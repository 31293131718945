window.addEventListener('DOMContentLoaded', () => {
  // Проставляем атрибут type всем button, у которых он еще не указан. Если button находится в form, то type="submit", иначе type="button".
  let buttons = document.querySelectorAll('button');

  if (buttons.length) {
    buttons.forEach(button => {
      if (!button.hasAttribute('type')) {
        if (button.closest('form')) {
          button.setAttribute('type', 'submit');
        } else {
          button.setAttribute('type', 'button');
        }
      }
    })
  }
  
  // Проставляем атрибут alt="" всем img, у которых он еще не указан.
  let imgs = document.querySelectorAll('img');

  if (imgs.length) {
    imgs.forEach(img => {
      if (!img.hasAttribute('alt')) {
        img.setAttribute('alt', '');
      }
    })
  }
});
